/* eslint-disable @typescript-eslint/no-explicit-any */
import TextInput from 'components/common/TextInput'
import { UserCredentials } from 'models/Auth'
import React, { useState } from 'react'
import loginContent from 'content/login.json'
import styled from 'styled-components'

const LoginContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;

  position: absolute;
  width: inherit;
  top: 30vh;
`

const LoginButton = styled.button`
  text-align: center;
  width: fit-content;
  border-radius: 30px;
  background-color: #a98d40;
  color: white;
  font-size: 14px;
  line-height: 30px;
  letter-spacing: 5%;
  font-weight: 600;
  width: 160px;
  height: 42px !important;
  border: 0px;
  cursor: pointer;

  &:focus-visible {
    outline-color: #a98d40;
    outline-offset: 0px;
  }

  &:disabled {
    opacity: 0.5;
  }
`

const Message = styled.p`
  color: #a98d40;
  margin-top: 24px;
  margin-bottom: 24px;
`
const MessageLink = styled.a`
  color: inherit !important;
`

const ErrorMessage = styled.span`
  color: #b14b44;
  font-weight: 400;
  line-height: 16px;
  font-size: 14px;
  margin-top: 24px;
`

interface LoginProps {
  isError?: boolean
  onSubmit: (credentials: UserCredentials) => void | Promise<void>
}

const Login: React.FC<LoginProps> = ({ onSubmit, isError }) => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const handleSubmit = (event: React.SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault()

    onSubmit({ username, password })
  }

  const handleUsername = (event: React.FormEvent<HTMLInputElement>) => {
    setUsername(event.currentTarget.value)
  }

  const handlePassword = (event: React.FormEvent<HTMLInputElement>) => {
    setPassword(event.currentTarget.value)
  }

  return (
    <LoginContainer onSubmit={handleSubmit}>
      <TextInput
        name='username'
        type='text'
        placeholder={loginContent.placeholders.username}
        isError={isError}
        onChange={handleUsername}
      />
      <TextInput
        name='password'
        type='password'
        placeholder={loginContent.placeholders.password}
        isError={isError}
        onChange={handlePassword}
      />

      <Message>
        {`${loginContent.message.text} `}
        <MessageLink target='_blank' href={loginContent.message.linkTo}>
          {loginContent.message.link}
        </MessageLink>
        .
      </Message>

      <LoginButton disabled={!username || !password} type='submit'>
        {loginContent.login}
      </LoginButton>

      {isError && <ErrorMessage>{loginContent.error}</ErrorMessage>}
    </LoginContainer>
  )
}

export default Login
