import styled from 'styled-components'

const Input = styled.input<{ isError?: boolean }>`
  text-align: center;
  width: 100%;
  margin-top: 12px;
  padding-top: 16px;
  padding-bottom: 16px;
  border-radius: 12px;
  background: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  font-style: italic;
  border: ${(props) => (props.isError ? '2px solid #B14B44 ' : 'none')};

  &:focus-visible {
    outline-color: #a98d40;
    outline-offset: 0px;
  }
`

export default Input
