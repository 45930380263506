import MainLayout from 'components/layouts/Main'
import * as React from 'react'
import appContent from 'content/app.json'
import styled from 'styled-components'
import Container from 'components/common/Container'
import HeaderSVG from 'assets/icons/banner.svg'
import { handleLogin } from 'services/auth'
import Login from 'components/custom/Login'
import Spinner from 'components/common/Spinner'
import { UserCredentials } from 'models/Auth'
import { navigate, PageProps } from 'gatsby'

const ContentContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
`

const HeaderContainer = styled(Container)`
  margin-top: 28px;
  margin-bottom: 53px;
`

const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const LoginPage: React.FC<Partial<PageProps>> = () => {
  const [isError, setIsError] = React.useState(false)
  const [isLoading, setIsloading] = React.useState(false)

  const handleSubmit = async ({ username, password }: UserCredentials) => {
    setIsloading(true)

    handleLogin({ username, password })
      .then(() => {
        navigate('/')
      })
      .catch(() => setIsError(true))
      .finally(() => setIsloading(false))
  }

  return (
    <MainLayout
      backgroundColor={appContent.backgroundColor}
      backgroundImage={appContent.backgroundImage}
      content={
        <ContentContainer>
          <HeaderContainer>
            <HeaderSVG />
          </HeaderContainer>

          <Login onSubmit={handleSubmit} isError={isError} />

          {isLoading && (
            <LoadingContainer>
              <Spinner />
            </LoadingContainer>
          )}
        </ContentContainer>
      }
    />
  )
}

export default LoginPage
